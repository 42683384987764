import { Col, Divider, Row, DatePicker, Space, Checkbox, Modal,message } from 'antd';
import { Button } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useParams} from 'react-router-dom';
/* eslint-disable */
import { getAllCars } from '../redux/actions/carsActions';
import moment from 'moment';
import { bookCar } from '../redux/actions/bookingActions';


import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const initialOptions = {
  "client-id": "AaYffCC0CiFXPVA2YjAj6xfAZ5vemqrY8M86z14oRsiFWdgfUU9gt8lqlq2qcu7CsNg7YXSlurwstM4N",//"AT2ScukjqQvK5_QDmuzw2w1a1pknz77QfEls7EBFw5h7KV1yJiXDi-ZnjiPbBqXUYrvtofDig0_h97Ta",
  "currency":"EUR",
  "enable-funding": ["card"],
  "components": "buttons",
  "data-sdk-integration-source": "integrationbuilder_sc",
  "intent":"capture"
};

message.config(
  {
      top: 100,
      duration: 5,
      maxCount: 3,
      rtl: true,
      prefixCls: 'my-message',
    }
)

function BookingCar() {

    const { carid } = useParams();
    const { cars } = useSelector(state => state.carsReducer)
    const {booking} = useSelector(state =>state.bookingsReducer)
    const {booking_id} = useParams();
    const [car, setcar] = useState({});
    const dispatch = useDispatch()
    const { RangePicker } = DatePicker;

    const [from, setFrom] = useState()
    const [to, setTo] = useState()

    const [name, setName] = useState()
    const [last_name, setLast_Name] = useState()
    const [birthday, setBithday] = useState()
    const [email, setEmail] = useState()

    const [totalDays, setTotalDays] = useState(0)
    const [driver, setDriver] = useState(false);
    const [tos, setTos] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [showTos, setShowTos] = useState(false)

    const [disabledRanges,setDisabledRanges] = useState()

    useEffect(() => {
        if (cars.length == 0) {
            dispatch(getAllCars())
            
        }
        else {
            setcar(cars.find(o => o._id == carid))

        }
        
    }, [cars])

    useEffect(() => {
      setTotalAmount(totalDays * car.rentPerHour)        
    }, [totalDays])

    async function selectedTimeSlots(values) {
       
        setFrom(moment(values[0].format('YYYY-MM-DD')).format('YYYY-MM-DD'))

        setTo(moment(values[1].format('YYYY-MM-DD')).format('YYYY-MM-DD'));
        setTotalDays(values[1].diff(values[0], 'days'))
        
        await store_booking_data();
    }
    
    /*
        const disabledDateRanges = [
      { from: 1691596800, to: 1691683200 }, // Example range 1
      { from: 1691769600, to: 1691856000 }  // Example range 2
    ];

    const disabledRanges = disabledDateRanges.map(range => ({
      from: moment.unix(range.from),
      to: moment.unix(range.to)
    }));
    */
    const isDateDisabled = (current) => {
      return disabledRanges.some(({ from, to }) =>
        current.isBetween(from, to, 'day', '[]')
      );
    };
    async function store_booking_data(){
      localStorage.setItem('booking',JSON.stringify({
        //user: JSON.parse(localStorage.getItem('token'))._id,
        car: car._id,
        name,
        last_name,
        birthday,
        email,
        totalDays,
        totalAmount,
        driverRequired: driver,
        bookedTimeSlots: {
            from,
            to
        }
    
    }))
    }

    function bookNow() {
        if (!driver) {
            return message.error("We can only rent to experienced drivers.")
        }
        if(!tos){
            return message.error("Please accept our terms of service.")
        }
        const reqObj = {
            //user: JSON.parse(localStorage.getItem('token'))._id,
            car: car._id,
            name,
            last_name,
            birthday,
            email,
            totalDays,
            totalAmount,
            driverRequired: driver,
            bookedTimeSlots: {
                from,
                to
            }
        
        }
        console.log("booking car...");
        dispatch(bookCar(reqObj))
    }


    return (
        <>
            <div className="container mx-auto flex flex-col sm:flex-col rounded shadow-lg mt-24 mb-14 md:flex-row bg-white px-8 py-14">
                <div className="w-full md:w-1/2  p-2 relative align-center text-center justify-center">
                    <img src={car.image} className='w-full  h-4/5  px-1 sm:px-2  md:px-5 lg:px-5 xl:px-7 py-1 sm:py-2  md:py-2 lg:py-3 xl:py-5 rounded-md hover:scale-125"' />
                    <div className='hidden sm:block absolute inset-0 w-full h-4/5 border-2 px-2 border-[#FCA311] transform -rotate-12 rounded-3xl'></div>
                    <div className=' mt-5 mx-0 sm:mx-1 md:mx-2 lg:mx-5 flex justify-between mb-4 shadow-lg px-2 '>
                        <div className='mt-6 space-y-2 mb-2'>

                            <h3 className=" text-sm flex font-semibold text-left capitalize sm:text-base md:text-base lg:text-lg xl:text-xl">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fb8500" class="w-5 h-5 sm:w-5 sm:h-5 md:w-6 md:h-6 lg:w-7 lg:h-7 lg:mr-2">
                                     <path stroke-linecap="round" stroke-linejoin="round" d="M16.712 4.33a9.027 9.027 0 0 1 1.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 0 0-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 0 1 0 9.424m-4.138-5.976a3.736 3.736 0 0 0-.88-1.388 3.737 3.737 0 0 0-1.388-.88m2.268 2.268a3.765 3.765 0 0 1 0 2.528m-2.268-4.796a3.765 3.765 0 0 0-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 0 1-1.388.88m2.268-2.268 4.138 3.448m0 0a9.027 9.027 0 0 1-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0-3.448-4.138m3.448 4.138a9.014 9.014 0 0 1-9.424 0m5.976-4.138a3.765 3.765 0 0 1-2.528 0m0 0a3.736 3.736 0 0 1-1.388-.88 3.737 3.737 0 0 1-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 0 1-1.652-1.306 9.027 9.027 0 0 1-1.306-1.652m0 0 4.138-3.448M4.33 16.712a9.014 9.014 0 0 1 0-9.424m4.138 5.976a3.765 3.765 0 0 1 0-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 0 1 1.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 0 0-1.652 1.306A9.025 9.025 0 0 0 4.33 7.288" />
                                </svg>

                                {car.name}
                            </h3>
                            
                            <p className='text-left flex capitalize text-sm mt-2 sm:text-sm md:text-base lg:text-base xl:text-lg'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#e63946" class="w-5 h-5 sm:w-5 sm:h-5 md:w-6 md:h-6 lg:w-7 lg:h-7 lg:mr-2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.362 5.214A8.252 8.252 0 0 1 12 21 8.25 8.25 0 0 1 6.038 7.047 8.287 8.287 0 0 0 9 9.601a8.983 8.983 0 0 1 3.361-6.867 8.21 8.21 0 0 0 3 2.48Z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 18a3.75 3.75 0 0 0 .495-7.468 5.99 5.99 0 0 0-1.925 3.547 5.975 5.975 0 0 1-2.133-1.001A3.75 3.75 0 0 0 12 18Z" />
                                </svg>

                                Fuel Type: {car.fuelType}
                            </p>
                        </div>
                        <div className='mt-6 space-y-2 '>

                            <p className='text-left flex capitalize text-sm sm:text-base md:text-base lg:text-base xl:text-lg'>
                                
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#219ebc" class="w-5 h-5 sm:w-5 sm:h-5 md:w-6 md:h-6 lg:w-7 lg:h-7 lg:mr-2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>

                                Rent:  
                                <span className='text-sm sm:text-sm md:text-base lg:text-base xl:text-lg ml-1 lg:ml-2 italic'>{car.rentPerHour}€ /Day</span> 
                            </p>
                            <p className='text-left flex  capitalize text-sm sm:text-base md:text-base lg:text-base xl:text-lg'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#588157" class="w-5 h-5 sm:w-5 sm:h-5 md:w-6 md:h-6 lg:w-7 lg:h-7 lg:mr-2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m21 7.5-2.25-1.313M21 7.5v2.25m0-2.25-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3 2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75 2.25-1.313M12 21.75V19.5m0 2.25-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25" />
                                </svg>

                                Capacity: <span className='ml-2 italic'>
                                {car.capacity}</span> </p>
                        </div>

                    </div>
                </div>

                <div className="w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mt-7 ml-0  sm:ml-0 md:ml-3 lg:ml-5 xl:ml-5">
                    <h2 className="text-3xl sm:text-3xl md:text-3xl lg:text-4xl font-bold mb-4 text-center mt-2 sm:mt-0 tracking-wider">Booking</h2>
                    <p className="text-gray-700 text-center sm:text-left">
                        Once you have booked please send us an email with a copy of your ID and Drivers License in order for us to prepare the rental contract in advance. 
                    </p>

                    <Divider type='horizontal' dashed>Driver details</Divider>
                    <form class="w-full max-w-lg">
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
        First Name
      </label>
      <input onChange={(v)=>{setName(v.target.value)}} required="true" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane"></input>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
        Last Name
      </label>
      <input onChange={(v)=>{setLast_Name(v.target.value)}} required="true" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Doe"></input>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-birthday">
        Email
      </label>
      <input onChange={(v)=>{setEmail(v.target.value)}} required="true" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-birthday" type="text" placeholder=""></input>
      <p class="text-gray-600 text-xs italic">Please follow the format: DD/MM/YYYY</p>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-birthday">
        Birthday
      </label>
      <input onChange={(v)=>{setBithday(v.target.value)}} required="true" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-birthday" type="text" placeholder="25/12/1982"></input>
      <p class="text-gray-600 text-xs italic">Please follow the format: DD/MM/YYYY</p>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-2">
    <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city">
        City
      </label>
      <input required="true" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-city" type="text" placeholder="City"></input>
    </div>
    <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        State
      </label>
      <div class="relative">
      <input required="true" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-city" type="text" placeholder="State"></input>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div>
    <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-zip">
        Zip
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-zip" type="text" placeholder="90210"></input>
    </div>
  </div>
</form>
                    <Divider type='horizontal' dashed>Time slots</Divider>
                    <div className=''>
                        <div className='flex justify-center mt-14 mx-3'>
                            <RangePicker
                            disabledDate={(current)=>{
                              return current && current < new Date().setHours(0,0,0,0);
                            }}
                            style={{ 
                                width: '500px', height:"50px", color:"black", fontSize: '28px', fontWeight: 'bold', border: '2px solid #219ebc', borderRadius: '20px', backgroundColor: '#8ecae6', 
                                }} 
                            format='YYYY-MM-DD' 
                            onChange={selectedTimeSlots}
                            />
                        </div>
                    </div>

                    <div className="mt-3 align-center text-center lg:px-20 mb-2">
                    
                        <Button className='align-center text-center bg-transparent hover:bg-yellow-500 text-yellow-700 font-semibold hover:text-white py-2 px-4 border border-yellow-500 hover:border-transparent rounded-md'
                            onClick={() => setShowModal(true)} 
                        >
                            <p className='text-sm sm:text-sm md:text-base lg:text-base text-black capitalize'>
                                Show booked slots
                            </p>
                        </Button>
                    </div>
                    <div className="mt-3 align-center text-center lg:px-20 mb-2">
                            {(totalAmount!==null) &&
                                <p className='text-sm sm:text-sm md:text-base lg:text-base text-black'>
                                  <div>
                                    <u>We only charge 30% of total booking price at this step, this because it helps us get an initial commitment. The rest (70%) will be charged once you have received the rental contract and picked up the car.
                                    </u>
                                  </div>
                                  <div>
                                  Total rental price : {totalAmount}€
                                  </div>
                                  
                                  <div>
                                  Amount to be paid today (30%): {(totalAmount/100)*30}€
                                  </div>
                                  <div>
                                  Amount to be paid on car pickup (70%): {(totalAmount/100)*70}€
                                  </div>
                                  
                                </p>
                            }
                            
                            

                    </div>


                 
                    <div className="divide-y divide-gray-200 mx-14">
                            <div className="relative flex items-start pb-4 pt-3.5">
                            <div className="min-w-0 flex-1 text-sm leading-6">
                                <label htmlFor="comments" className="font-bold text-gray-900 text-base">
                                    Experience
                                </label>
                                <p id="comments-description" className="text-gray-500">
                                   Experienced driver (driver license for more than 2 years)
                                </p>
                            </div>
                            <div className="ml-3 flex h-6 items-center">
                                <input
                                id="comments"
                                aria-describedby="comments-description"
                                name="comments"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setDriver(true)
                                    } else {
                                        setDriver(false)
                                    }
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="divide-y divide-gray-200 mx-14">
                            <div className="relative flex items-start pb-4 pt-3.5">
                            <div className="min-w-0 flex-1 text-sm leading-6">
                                <p id="comments-description" className="text-gray-500">
                                   I accept the <u><a onClick={() => setShowTos(true)} >terms of service</a></u>.
                                </p>
                            </div>
                            <div className="ml-3 flex h-6 items-center">
                                <input
                                id="comments"
                                aria-describedby="comments-description"
                                name="comments"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setTos(true)
                                    } else {
                                        setTos(false)
                                    }
                                }}
                                />
                            </div>
                        </div>
                    </div>

                   
                    <div className='flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row mx-8 space-x-2 mt-4 space-y-4 sm:space-y-4 md:space-y-0 slgm:space-y-0'>
                        <button 
                                className='w-full rounded-full bg-[#1F2937] text-white font-bold  py-2  px-4 border border-[#1F2937] hover:bg-[#1F2937] hover:text-white'
                                
                                onClick={()=>window.history.back()}
                            >
                                Back
                        </button>

                        
                        <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "horizontal",
            color:"blue"
          }}
          onclick={store_booking_data()}
          createOrder={async () => {
            try {
            const booking = JSON.parse(localStorage.getItem('booking'));
            console.log(booking);
            if (booking == null){
              throw Error("No booking data saved")
            }
            
              const response = await fetch("/api/bookings/bookcar", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                // use the "body" param to optionally pass additional order information
                // like product ids and quantities
                body: JSON.stringify(booking)
              });

              const data = await response.json();
              if(data.reason.includes("car_not_available_for_selected_period") && data.reason){
                throw new Error("Selected car is not available for selected period");
              }
              const orderData = data.paypal;
              console.log(orderData);
              if (orderData.id) {
                return orderData.id;
              } else {
                const errorDetail = orderData?.details?.[0];
                const errorMessage = errorDetail
                  ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                  : JSON.stringify(orderData);

                throw new Error(errorMessage);
              }
            } catch (error) {
              console.error(error);
              message.error(`Could not complete payment. ${error}`)
            }
          }}
          onApprove={async (data, actions) => {
            try {
              console.log(data);
              const response = await fetch(
                `/api/bookings/booking/${data.orderID}/capture`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                },
              );
              console.log("Riposta di paypal")
              console.log(response);

              const orderData = await response.json();
              // Three cases to handle:
              //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
              //   (2) Other non-recoverable errors -> Show a failure message
              //   (3) Successful transaction -> Show confirmation or thank you message

              const errorDetail = orderData?.details?.[0];

              if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                return actions.restart();
              } else if (errorDetail) {
                // (2) Other non-recoverable errors -> Show a failure message
                throw new Error(
                  `${errorDetail.description} (${orderData.debug_id})`,
                );
              } else {
                // (3) Successful transaction -> Show confirmation or thank you message
                // Or go to another URL:  actions.redirect('thank_you.html');
                const transaction =
                  orderData.purchase_units[0].payments.captures[0];
                message.error(`Transaction ${transaction.status}: ${transaction.id}. See console for all available details`)
                console.log(
                  "Capture result",
                  orderData,
                  JSON.stringify(orderData, null, 2),
                );
                setTimeout(()=>{
                  window.history.push("/");
                },10000)

              }
            } catch (error) {
              console.error(error);
              message.error(`Sorry, your transaction could not be processed...${error}`)
            }
          }}
        />
      </PayPalScriptProvider>
                    </div>
                </div>

                
            </div>
            {car.name && (
                <Modal title="Booked time slots" open={showModal} closable={false} footer={false}  >
                {
                    cars.length && (<div className='p-2'>
                        
                        {car.bookedTimeSlots.map(slot=>{
                            return <Button type="primary" size='large' className='mt-3' >
                            {slot.from} - {slot.to}
                        </Button>
                        })}

                        <div className='text-right'>
                        <Button type="primary" className='mt-3' onClick={()=>setShowModal(false)} >
                            Close
                        </Button>
                        </div>
                    </div>)
                }
        </Modal>
        
      )}
      <Modal title="Terms of Service" open={showTos} closable={false} footer={false}  >
                {
                    (<div className='p-2'>
                        
                        <p>
                        (1) RENT A CAR KOLI keeps a copy of the passport or identity card as well as the driving license of the lessee attached to the contract.
                        </p>
                        <p>
(3) In the event of an accident, the Lessee is obliged not to move the vehicle from the scene, to take note of the license plate of the other vehicle if it leaves, to immediately notify the police and the nearest agency of RENT A CAR KOLI, to describe the incident, and to complete the necessary documentation (standard forms).

                        </p>
                        <p><b>
                        VIOLATIONS
                        </b></p>
                        <p>
                        The Lessee: Is charged with the legal responsibility that the rented vehicle is not to be used for passenger or freight transport, not to be sublet, not to be used for towing vehicles or other items, not to be used for racing, not to be used for illegal purposes, not to be used recklessly, not to be driven by unauthorized persons without written authorization from the Lessor, not to load the vehicle with passengers or items beyond its capacity.
                        </p>
                        <div></div>
                        <p>
                        Under no circumstances shall the Lessor be liable for goods that may be left in the vehicle during or at the end of the rental period. The legal consequences arising from the actions or inactions of the Lessee during the rental period, with or without the Lessor's vehicle, are his exclusive responsibility, whether civil or criminal. The Lessee is liable for violations as follows:
                        </p>
                        <ul>
                            <li>
                            The Lessee shall pay the value of fines imposed during the rental period and only 40% surcharge on this value when declared to the Lessor, if not paid by himself, and quadruple the value of fines in case of concealment.
                            </li>
                            <li>
                            The Lessee shall cover the full value of the damage if, in the event of an accident, he does not keep and submit a copy of the police report on the incident.
                            </li>
                            <li>
                            The Lessee shall cover the full value of damages not covered by insurance policies, including: tire damage or punctures, damage to wheel discs, damage or loss of wheel cover plates, engine damage, fuel pump or gasoline damage due to poor fuel use, chassis damage, damage caused under the influence of alcohol or various drugs, intentional damage caused by explosions, fires, explosives, or burning of the car's interior upholstery, damage caused by unauthorized drivers.
                            </li>
                            <li>
                            The Lessee shall pay the value of fines imposed during the rental period and only 40% surcharge on this value when declared to the Lessor, if not paid by himself, and quadruple the value of fines in case of concealment.
                            </li>
                            <li>
                            The Lessee shall pay 10€ per hour of delay, up to the value of a full day of rental of the aforementioned vehicle.
                            </li>
                            <li>
                            The Lessee shall pay 5€ if the vehicle is returned uncleaned inside and out.
                            </li>
                            <li>
                            The Lessee shall pay 25€ if smoking is detected in the vehicle.
                            </li>
                        </ul>
                        <div></div>
                        <h3>
                            <b>FINAL PROVISIONS</b>
                        </h3>
                        <p>
                        If the Lessee acts contrary to the above provisions, and the Lessor assesses that there is a risk of damage to the vehicle, the Lessor has the right to terminate the contract immediately and unilaterally and take back the vehicle without notice. The Lessor also reserves the right to seek compensation for damages, if any. The contract is drawn up in two identical copies in the Albanian language and enters into force at the time of signing. The parties, having read it carefully and attentively, acknowledge that they understand the terms of the contract and that the vehicle has been delivered and received under the conditions described in the contract and its annexes, and they sign it freely.
                        </p>
                        
                        <div className='text-right'>
                        <Button type="primary" className='mt-3' onClick={()=>setShowTos(false)} >
                            Close
                        </Button>
                        </div>
                    </div>)
                }
        </Modal>
        </>
    )
}

export default BookingCar;